<template>
  <div class="download">
    <a @click="downloadInstall()" :href="`${apkUrl}`">安卓下载</a>
  </div>
</template>

<script>
  import utils from '../utils/utils'
  export default {
    name: 'Download',
    components: {},
    setup () {
      let env = window.env
      let apkUrl = `/apks/${env.version}/${env.app}-${env.version}-release-${env.channel}.apk`
      function downloadInstall () {
        utils.statsDownload()
      }
      return {
        downloadInstall,
        apkUrl,
        env
      }
    }
  }
</script>

<style scoped>
  a {
    text-decoration: none;
  }
  .download{
    font-weight: 900;
    font-size: 1.5rem;
  }
  .download a{
    color: #000;
    max-width: 7rem;
    min-width: 3rem;
    border: 2px solid #000;
    border-radius: 25px;
    padding: 5px 3rem;
    background-color: #fff45c;
  }
</style>
