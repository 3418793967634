<template>
  <div class="title">
    为什么大家都选择{{appName}}
  </div>
  <div style="height: 2rem;"></div>
  <div class="desc">
    {{appName}}由硅谷资深技术专家团队打造，使用高质量网络服务和独有的技术加密协议，在保证稳定的情况下提供不限流量不限速度的优质线路，是看高清视频、玩游戏和使用社交网络的不二之选。
  </div>
</template>

<script>

  export default {
    name: 'WhyNeed',
    components: {},
  }
</script>

<style scoped>
div{
  padding: 0 5%;
}
  .title{
    text-align: center;
    font-weight: 900;
    font-size: 2rem;
  }
  .desc{
    color: #696969;
    font-size: 1.2rem;
  }


</style>
