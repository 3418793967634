<template>
  <div class="offer">
    <div class="info">
      <div>
        <div class="title">
          迅速
        </div>
        <div class="desc">
          {{appName}}可以根据您的当前网络环境，选择最优的网络线路，从而使您获得最好的网络提验。
        </div>
      </div>
      <div  style="order: -1;" class="img">
        <img src="@/assets/images/gaosu.png" alt="">
      </div>
    </div>
    <div class="info">
      <div>
        <div class="title">
          简单
        </div>
        <div class="desc">
          一键连接即可连接全球，无需复杂设置和操作，轻松访问各国网站和应用
        </div>
      </div>
      <div class="img">
        <img src="@/assets/images/wendin.png" alt="">
      </div>
    </div>
    <div class="info">
      <div>
        <div class="title">
          安全
        </div>
        <div class="desc">
          数据传输基于双重加密，不会记录您任何的数据，保护隐私。匿名上网，保护您的真实IP不被泄露，保护个人信息安全。
        </div>
      </div>
      <div  style="order: -1;" class="img">
        <img src="@/assets/images/anquan.png" alt="">
      </div>
    </div>

  </div>
</template>

<script>

  export default {
    name: 'Offer',
    components: {},
    setup () {

    },
    created () {
    }
  }
</script>

<style scoped>
  div {
    text-align: center;
  }

  img {
    max-width: 250px;
  }

  .offer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 5%;
  }

  .title {
    font-size: 2rem;
    font-weight: 900;
    margin: 2rem 0;
  }

  .desc {
    color: #696969;
    padding: .5rem 2rem;
  }

  .info {
    width: 80%;
    background-color: #ffffff;
    border: 2px solid;
    border-radius: 15px;
    margin: 2rem 0;
    box-shadow: -3px 3px 2px 0px rgb(0 0 0 / 30%);
  }
  .info img {
    padding: 1.5rem 5%;
    width: 70%;
  }

  @media all  and (min-width: 800px) {
    .info {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .offer .info:first-child {
      margin-top: 2rem;
    }
  }
</style>
