<template>
  <Banner></Banner>
  <div style="height: 2rem;"></div>
  <div class="main-desc">
    <WhyNeed></WhyNeed>
    <Offer></Offer>
<!--    <Choose></Choose>-->
  </div>
    <div class="center bottom-download">
      <Download></Download>
      <div style="height: 1.1rem;"></div>
      <div>
        <span>
          客服邮箱: {{email}}
        </span>
      </div>
      <div style="height: 1.1rem;"></div>
    </div>
  <div class="copyright">
    <Copyright></Copyright>
  </div>
  <div class="contact-us"><ContactUs></ContactUs></div>
</template>
<script>
  import Banner from "./components/Banner"
  import WhyNeed from "./components/WhyNeed"
  import Offer from "./components/Offer"
  // import Choose from "./components/Choose"
  import Download from "./components/Download"
  import Copyright from "./components/Copyright"
  import ContactUs from "./components/ContactUs"

  export default {
    name: 'App',
    components: {
      Banner,
      WhyNeed,
      Offer,
      // Choose,
      Download,
      Copyright,
      ContactUs,
    },
  }
</script>
<style>
  * {
    padding: 0;
    margin: 0;

  }

  html {
    font-size: 12px;
    background-color: #fafafa;
  }

  .center {
    text-align: center;
  }

  .bottom-download {
    height: 8rem;
    background-color: #49a79f;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
  }

  .copyright {
    background-color: #49a79f;
    color: white;
  }

  .contact-us{
    position: fixed;
    bottom: 1rem;
    right: 1rem;
  }

  @media all  and (min-width: 800px) {
    html {
      font-size: 14px;
    }
    .main-desc {
      /*max-width: 800px;*/
      padding: 2rem 10% ;
    }
  }
</style>
