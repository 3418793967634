<template>
  <div class="banner">
    <div class="slogan" v-html="slogan">
    </div>
    <div style="height: 3rem;"></div>
    <Download></Download>
    <div style="height: 1rem;"></div>
    <div class="record">
      <div>
        <div class="number">已有 <i>{{renshu}}</i> 加入 {{appName}}</div>
      </div>
    </div>
    <div class="poster">
      <img src="@/assets/images/banner.jpg" alt="">
    </div>
  </div>
</template>

<script>
  import Download from "./Download"
  import utils from "../utils/utils"
  import { computed, ref } from "vue"

  export default {
    name: 'Banner',
    components: {
      Download
    },
    setup () {


      let renshu = computed(() => {
        return utils.commalize(val.value.toString())
      })
      let val = ref(1000000)
      let env = window.env
      return {
        env,
        val,
        renshu,
      }
    },
    created () {
      let valNumber = utils.getHashNumber(this.appName, 111111)
      let valTimeNumber = new Date().getTime()
      this.val = valNumber + utils.subNumber(valTimeNumber)
      setInterval( ()=> {
        this.val++
      }, 1000*60)
    }

  }
</script>

<style scoped>

  img {
    width: 100%;
  }

  .banner {
    color: #ffffff;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 5rem 0 1rem 0;
    background-color: #49a79f;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .slogan {
    padding: .4rem;
    font-size: 3rem;
    font-weight: 900;
    line-height: 3.3rem;
    letter-spacing: 2px;
  }

  .record {
    text-align: right;
    padding: 1rem 10%;
    width: 80%;
  }

  .poster {
    width: 100%;
    max-width: 500px;
  }

  .link a {
    color: #ffff56db
  }

  @media all and (max-width: 800px) and (min-width: 150px) {
    .slogan {
      padding: .4rem;
      font-size: 2.5rem;
      font-weight: 600;
    }
  }

  @media all  and (min-width: 800px) {
    .slogan {
      letter-spacing: 1rem;
      text-indent: 1rem;
      line-height: 4rem;
    }
  }
</style>
