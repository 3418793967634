<template>
  <div class="copyright">
    © {{new Date().getFullYear()}} {{appName}} {{companyName}} All Rights Reserved.
  </div>
</template>

<script>

  export default {
    name: 'Copyright',
    components: {},
    setup () {
    }
  }
</script>

<style scoped>
.copyright{
  padding: 6px 1rem;
}

</style>
